import React, { useEffect, useState } from 'react';
import Pagination from './Pagination';
import ServicioFinalizado from './ServicioFinalizado';
import LandingPage from '../LandingPage';
import { useAuth } from '../../context/AuthContext';
import { WEB_SERVICES_URL } from '../../constants/Url';

const Servicios = (props) => {
  const [serviciosData, setServiciosData] = useState([]);
  const { verifyToken, isLoggedIn, logout } = useAuth();
  const {setLastVisitedRoute} = props;
  setLastVisitedRoute('/finalizados');

  let token = null;


  useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      token = params.get('token') ?? localStorage.getItem('id_token');
      // console.log("servicios is logged in: "+isLoggedIn());
      // console.log("servicios token: "+token);
      // console.log("localstorage id_token: "+localStorage.getItem('id_token'));
      
      if (!isLoggedIn()) {
          handleLoginWithToken(token);
      } else if (isLoggedIn()) {
          handleLoginWithToken(token);
      }
  }, []); // Agrega isLoggedIn a las dependencias del useEffect

  const handleLoginWithToken = async (token) => {
      try {
          if(token === undefined) {
              console.log(token);
              logout();
              return;
          }
          // console.log("ok "+token);
          await verifyToken(token);
      } catch (error) {
          console.error('Error al iniciar sesión:', error);
      }
  };

  useEffect(() => {
    // Hacer la solicitud HTTP a tu servicio REST
    fetch(WEB_SERVICES_URL+'/limpieza/finalizados/'+token)
      .then(response => response.json())
      .then(data => setServiciosData(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    isLoggedIn() ? <>
    <div className="grid">
      {serviciosData.length === 0 ? <p className="text-center">No tienes servicios completados hasta el momento</p> : <p></p>}
      {serviciosData.map((servicio, index) => (
        <ServicioFinalizado
          key={index}
          id={servicio._id}
          tipoServicio={servicio.tipoServicio}
          metrosCuadrados={servicio.metrosCuadrados}
          habitaciones={servicio.habitaciones}
          banos={servicio.banos}
          equiposSuministros={servicio.equiposSuministros}
          fechaHorario={new Date(servicio.fechaHorario).toLocaleString('es-ES', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          })}
          presupuestoCliente={servicio.presupuestoCliente}
          serviciosAdicionales={servicio.serviciosAdicionales}
          calificacionCliente={servicio.calificacionCliente}
        />
      ))}
      
    </div>
    <Pagination />
    </> : 
    <>
    <LandingPage />
    </>
  );
};

export default Servicios;
