import React, { useEffect, useState } from "react";
import axios from "axios";
import { Switch, Text, HStack, Button, ButtonGroup  } from "@chakra-ui/react";

import { useAuth } from '../../context/AuthContext';
import { WEB_SERVICES_URL } from '../../constants/Url';

const ChangeMode = () => {
    const { mode, setMode } = useAuth();

  const handleClickCambiarModo = (mode) => {
    const token = localStorage.getItem('id_token') ?? new URLSearchParams(window.location.search).get('token');

    axios.put(WEB_SERVICES_URL+`/users/changemode/update`, { mode: mode, token: token })
      .then(response => {
      })
      .catch(error => {
        console.error('Error al cambiar modo:', error);
      });
  };

  const handleSwitchChange = () => {
    setMode(mode === "cliente" ? "proveedor" : "cliente");
  };

  useEffect(() => {
    if (mode) {
      handleClickCambiarModo(mode);
    }
  }, [mode]);
  

  return (
    <HStack spacing={4} padding={5} align="center">
    <ButtonGroup isAttached>
      <Button 
        colorScheme={mode === "cliente" ? "teal" : "gray"} 
        onClick={() => setMode("cliente")}
      >
        Cliente
      </Button>
      <Button 
        colorScheme={mode === "proveedor" ? "teal" : "gray"} 
        onClick={() => setMode("proveedor")}
      >
        Proveedor
      </Button>
    </ButtonGroup>
  </HStack>
  );
};

export default ChangeMode;
