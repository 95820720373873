import { ChakraProvider } from "@chakra-ui/react";
import './App.css';

import React, {useState,useEffect} from 'react';
import { MemoryRouter, BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './header/Header';
import Servicios from './main/servicios/Servicios';
import ServiciosPendientes from './main/servicios/ServiciosPendientes';
import ServiciosFinalizados from './main/servicios/ServiciosFinalizados';
import ClienteServiciosSolicitados from './main/servicios/cliente/ClienteServiciosSolicitados';
import ClienteServiciosPendientes from './main/servicios/cliente/ClienteServiciosPendientes';
import ClienteServiciosFinalizados from './main/servicios/cliente/ClienteServiciosFinalizados';
import About from './main/About';

import Footer from './footer/Footer';
import Leftside from './main/leftside/Leftside';
import SolicitudLimpieza from './forms/SolicitudLimpieza';
import { useAuth } from './context/AuthContext';
import Login from './main/login/Login';
import Logout from './main/login/Logout';
import { AlertProvider } from "./context/alertContext";
import Alert from "./forms/Alert";


const App = () => {
    const { verifyToken, isLoggedIn, logout, mode } = useAuth(); // Agrega la función isLoggedIn del contexto AuthContext
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [lastVisitedRoute, setLastVisitedRoute] = useState(() => {
    // Recuperar la última ruta visitada almacenada en el almacenamiento local
    return localStorage.getItem('lastVisitedRoute') || '/';
    });

    useEffect(() => {
    // Almacenar la ruta actual en el almacenamiento local cada vez que cambie
    const handleRouteChange = () => {
        setLastVisitedRoute(window.location.pathname);
    };

    window.addEventListener('popstate', handleRouteChange);

    return () => {
        window.removeEventListener('popstate', handleRouteChange);
    };
    }, []);

    useEffect(() => {
    // Almacenar la última ruta visitada en el almacenamiento local
    localStorage.setItem('lastVisitedRoute', lastVisitedRoute);
    
    const nuevaURL = window.location.pathname;
    window.history.pushState({}, document.title, nuevaURL);
    
    }, [lastVisitedRoute]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token') ?? localStorage.getItem('id_token');
        
        if (!isLoggedIn()) {
            handleLoginWithToken(token);
        } else if (isLoggedIn()) {
            // Verifica si el usuario ya está logeado
            // Si el usuario ya está logeado, redirige a otra página, por ejemplo:
        }
    }, []); // Agrega isLoggedIn a las dependencias del useEffect

    const handleLoginWithToken = async (token) => {
        try {
            if(token === undefined) {
                console.log(token);
                logout();
                window.location.href = "/";
                return;
            }
            await verifyToken(token);
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
        }
    };

  return (
    <MemoryRouter initialEntries={[lastVisitedRoute]} initialIndex={0}>
        <ChakraProvider>
            <AlertProvider>
                <Leftside isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen}/>
                <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen}/> 
                <div className="w3-main" style={{marginLeft:300}}>
                    <Routes>
                        <Route path="/" element={mode === 'cliente' ? <SolicitudLimpieza  setLastVisitedRoute={setLastVisitedRoute}/> : <Servicios setLastVisitedRoute={setLastVisitedRoute}/>} />
                        <Route path="/solicitados" element={mode === 'cliente' ? <ClienteServiciosSolicitados setLastVisitedRoute={setLastVisitedRoute}/> : <></>} />
                        <Route path="/pendientes" element={mode === 'cliente' ? <ClienteServiciosPendientes setLastVisitedRoute={setLastVisitedRoute}/> : <ServiciosPendientes setLastVisitedRoute={setLastVisitedRoute}/>} />
                        <Route path="/finalizados" element={mode === 'cliente' ? <ClienteServiciosFinalizados setLastVisitedRoute={setLastVisitedRoute}/> : <ServiciosFinalizados setLastVisitedRoute={setLastVisitedRoute}/>} />
                        <Route path="/about" element={<About setLastVisitedRoute={setLastVisitedRoute}/>} />
                        <Route path="/login" element={<Login setLastVisitedRoute={setLastVisitedRoute}/>} />
                        <Route path="/logout" element={<Logout setLastVisitedRoute={setLastVisitedRoute}/>} />
                    </Routes>
                    <Footer />  
                    <Alert/>
                </div>
            </AlertProvider>
        </ChakraProvider>
    </MemoryRouter>

  );
};

export default App;